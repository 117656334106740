import { mapState } from 'vuex'
import { app } from '@/config'
export default {
  name: 'Homepage',
  created () {
  },
  computed: {
    ...mapState('user', ['currentUser']),
    roles () {
      return this.currentUser.roles
    }
  },
  methods: {
    redirectTo (page) {
      this.$router.push(page.toLowerCase())
    },
    featureName (feature) {
      return app.features[feature]
    }
  }
}
